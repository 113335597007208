import React from 'react'
import CreativeWallet from '../../Components/CreativeDashboard/CreativeWallet'

const CreativeWalletDash = () => {
  return (
    <div>
      <CreativeWallet />
    </div>
  )
}

export default CreativeWalletDash
